import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';
import * as d3 from 'd3'
// secret tic tac toe tutorial

function Square({value, onSquareClick}){
  
  return(

   <button 
  className="square" onClick={onSquareClick}
  >
    {value}
  </button>
  );
}


function Reset({ onResetClick}){
  return (
    <button 
  className="reset" onClick={onResetClick}
  >Reset
  </button>
  )
}


export function TicTacToeBoard() {
  const[xIsNext, setXIsNext] = useState(true);
  const [squares, setSquares] = useState(Array(9).fill(null));
const winner = calculateWinner(squares)
let status;
if (winner) {
  status = "Winner: " + winner;
} else {
  status = "Next Player: " + (xIsNext ? "X" : "O")
}

  function handleClick(i){
    if (squares[i] || calculateWinner(squares)){
      return;
    }
    const nextSquares = squares.slice()
    if (xIsNext) {
      nextSquares[i] = "X";
    } else {
      nextSquares[i] = "O";
    }
    setSquares(nextSquares);
    setXIsNext(!xIsNext);
  }

function handleResetClick(){
  setSquares(Array(9).fill(null));
  setXIsNext(true);
}

  return ( 
    <>
    <div className="status">{status}</div>
    <div className="board-row">
  <Square value={squares[0]} onSquareClick={() => handleClick(0)} />
  <Square value={squares[1]} onSquareClick={() => handleClick(1)}/>
  <Square value={squares[2]} onSquareClick={() => handleClick(2)}/>
  </div>
  <div className="board-row">
  <Square value={squares[3]} onSquareClick={() => handleClick(3)}/>
  <Square value={squares[4]} onSquareClick={() => handleClick(4)}/>
  <Square value={squares[5]} onSquareClick={() => handleClick(5)}/>
  </div>
  <div className="board-row">
  <Square value={squares[6]} onSquareClick={() => handleClick(6)}/>
  <Square value={squares[7]} onSquareClick={() => handleClick(7)}/>
  <Square value={squares[8]} onSquareClick={() => handleClick(8)}/>
  </div>
  <Reset onResetClick={() => handleResetClick()} />
  </>
  );
}

function calculateWinner(squares) {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6]
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a];
    }
  }
  return null;
}

//end tic tac toe tutorial





function App() {
  

  return (
    <div className="App">
      <header className="App-header">
        <TicTacToeBoard />
      </header>
    </div>
  );
}

export default App;
