// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 50vh;
  min-width: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#BarChart { 
  display: flex; 
  flex-direction: row; /* Arrange the Bars Horizontally using FlexBox  */
  align-items: flex-end; /* Have the bars start at the bottom and go up */
  justify-content: center; /* Center the bars in the middle of the screen */
  margin: 50px;
}

.bar {
  background-color: #3aa70f; /* Have each bar be a nice green */
  width: 10px; /* The animation will start with each bar being 10px wide, and then expand outwards */
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB,EAAE,iDAAiD;EACtE,qBAAqB,EAAE,gDAAgD;EACvE,uBAAuB,EAAE,gDAAgD;EACzE,YAAY;AACd;;AAEA;EACE,yBAAyB,EAAE,kCAAkC;EAC7D,WAAW,EAAE,qFAAqF;AACpG","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 50vh;\n  min-width: 50vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n#BarChart { \n  display: flex; \n  flex-direction: row; /* Arrange the Bars Horizontally using FlexBox  */\n  align-items: flex-end; /* Have the bars start at the bottom and go up */\n  justify-content: center; /* Center the bars in the middle of the screen */\n  margin: 50px;\n}\n\n.bar {\n  background-color: #3aa70f; /* Have each bar be a nice green */\n  width: 10px; /* The animation will start with each bar being 10px wide, and then expand outwards */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
