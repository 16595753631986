import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TopRightLogo from './Images/AECCMLogo.png';
import D3Charts from './D3Charts';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>


<ul class="navigation">
  <li><a href="#">John Doe</a></li>

</ul>

<div class="wrapper">
  <header class="header">SCHEDULE COMPASS</header>
  <div class="header_subscript">Welcome back, John Doe</div>
  <div class="NavBox">   
  <a href="#">Organization Overview</a>
  <a href="#">Project 1</a>
  <a href="#">Project 2</a>
  <a href="#">Project 3</a></div>
  <div class="GanttSpace">Gantt Goes Here<App /></div>
  <div class="TableVarianceSpace">Table Variance Goes Here <App /></div>
  <div class="WorkCompleteSpace">Percentage Complete Goes Here<App /></div>
  <div class="RiskIndicatorDelaySpace">Risk Indicator: Delays<App /></div>
  <div class="RiskIndicatorInaccuracySpace">Risk Indicator: Inaccuracy<App /></div>
  <div class="RiskIndicatorInefficiencySpace">Risk Indicator: Inefficiency<App /></div>
  <footer class="footer"> <div class="bottomimg" >
    <a href="https://www.aeccm.com/" target='_blank'><img src={TopRightLogo} alt="sometext" object-fit="scale-down" /></a>
</div></footer>
</div>
   


  

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
