// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#BarChart { 
    display: flex; 
    flex-direction: row; /* Arrange the Bars Horizontally using FlexBox  */
    align-items: flex-end; /* Have the bars start at the bottom and go up */
    margin: 50px;
  }
  
  .bar {
    background-color: #3aa70f; /* Have each bar be a nice green */
    width: 10px; /* The animation will start with each bar being 10px wide, and then expand outwards */
  }`, "",{"version":3,"sources":["webpack://./src/D3Charts.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB,EAAE,iDAAiD;IACtE,qBAAqB,EAAE,gDAAgD;IACvE,YAAY;EACd;;EAEA;IACE,yBAAyB,EAAE,kCAAkC;IAC7D,WAAW,EAAE,qFAAqF;EACpG","sourcesContent":["#BarChart { \n    display: flex; \n    flex-direction: row; /* Arrange the Bars Horizontally using FlexBox  */\n    align-items: flex-end; /* Have the bars start at the bottom and go up */\n    margin: 50px;\n  }\n  \n  .bar {\n    background-color: #3aa70f; /* Have each bar be a nice green */\n    width: 10px; /* The animation will start with each bar being 10px wide, and then expand outwards */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
